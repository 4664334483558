export function onDOMReady(fn: () => void) {
  if (document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

export function dispatchPushStateEvent() {
  const pushState = history.pushState;
  history.pushState = function (data: any, title: string, url?: string | null) {
    if (typeof history.onpushstate == "function") {
      history.onpushstate({ state: data });
    }
    const event = new Event("pushstate");
    pushState.apply(history, [data, title, url]);
    dispatchEvent(event);
    return;
  };
}
